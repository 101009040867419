import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Card,
  Chip,
  Stack,
  Typography,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { TStudyProgram } from '../../types/common';
import { WebContext } from '../../contextProvider/webContextProvider';
import { ProgramResponse } from '../../Service';

const degreeInterestOptions: any = [
  {
    type: 'Diploma/Certificate',
    value: '1',
  },
  {
    type: 'Associates Degree',
    value: '2',
  },
  {
    type: "Bachelor's Degree",
    value: '3',
  },
  {
    type: "Master's Degree",
    value: '4',
  },
];
const LeadFormMProgram = ({ onNext, leadFormData, setLeadFormData }: any) => {
  const [programs, setPrograms] = useState<TStudyProgram[]>([]);
  const { webContent } = React.useContext(WebContext);
  const hasRun = useRef(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // const fetchedMProgram = async () => {
  //   const programResponse = await ProgramResponse(
  //     `/v1/area/study?id=lookup%23Category${leadFormData.areaStudy.category_name}`
  //   );
  //   setPrograms(programResponse.value.programs);
  // };
  // useEffect(() => {
  //   fetchedMProgram();
  // }, []);

  const handleSubmit = (programs: string) => {
    setLeadFormData({
      ...leadFormData,
      degreeInterest: programs,
    });
    onNext();
  };

  useEffect(() => {
    if (leadFormData?.degreeInterest && !hasRun.current) {
      // Call the function you want to run only once
      onNext();
      hasRun.current = true;
    }
  }, []);
  
  return (
    <Box>
      <Stack display="inline-flex" mb={3}>
        <Typography
          component="h5"
          fontSize="30px"
          maxWidth="600px"
          lineHeight="1.3"
          mx="auto"
          fontWeight="800"
          mb={2}
        >
          {`Great! Now, Select the degree you would like to earn`} :
        </Typography>
      </Stack>
      <Box display="flex" flexWrap="wrap" gap="20px">
        {/* {programs.map(programName =>
          <Card className="radio-btn frm-card" onClick={() => handleSubmit(programName as string)}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography fontWeight="600" lineHeight="1.2" fontSize="13px" px={2} sx={{ height: "10px" }}>
                {programName.program_name}
              </Typography>
            </CardContent>
          </Card>
        )} */}
      {degreeInterestOptions.map((interestName:any) =>
          <Card className="radio-btn frm-card"  sx={{
            '&:hover': {
              outline: `2px solid ${webContent?.defaultData?.primaryColor}`,
            },
          }} onClick={() => handleSubmit(interestName?.value as string)}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography fontWeight="600" lineHeight="1.2" fontSize="13px" px={2} sx={{ height: "10px" }}>
                {interestName?.type}
              </Typography>
            </CardContent>
          </Card>
        )} 
        {/* <FormControl sx={{ width: '300px', display: 'flex', m: '8px auto 0' }}>
          <InputLabel>Degree Level</InputLabel>
          <Select
            sx={{ backgroundColor: '#ffffff !important' }}
            label="Degree Level"
            value=""
            onChange={(event: any) => {
              setLeadFormData({
                ...leadFormData,
                degreeInterest: event.target.value,
              });
              onNext();
            }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {degreeInterestOptions.map((val: any) => (
              <MenuItem value={val?.value} key={val?.value}>
                {val?.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Box>
    </Box>
  );
};

export default LeadFormMProgram;
